import React, { useState } from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageContainer } from './layout/Image-Container';
import { device, colors } from './layout/GlobalStyles';
import { Button } from './layout/StyledComponents';

const StyledHomeReview = styled.div`
    color: ${colors.offWhite};
    margin-bottom: 287px;

    .primary-font {
        font-family: 'lato';
    }
    .hero-image {
        filter: brightness(50%);
        z-index: -2;
        height: 100vh;

        padding-bottom: 54px;
        width: 170%;
        position: absolute;
        right: 00px;
    }
    .flash-up {
        animation: flashup 0.2s ease-in;
    }
    .flash {
        animation: flash 0.4s ease-in;
    }
    @keyframes flashup {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    @keyframes flashup {
        from {
            transform: translateY(20%);
            opacity: 0;
        }

        to {
            transform: translateY(00%);
            opacity: 1;
        }
    }
    .white-line {
        height: 1px;
        margin-bottom: 40px;
        width: 87vw;
        background: ${colors.offWhite};
    }

    @media ${device.tablet} {
        margin-top: 80px;
        margin-bottom: 0px;
        .white-line {
            width: 52vw;
        }

        .hero-image {
            padding-bottom: 120px;
            z-index: -2;
            height: 70%;
            width: 150%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptopS} {
        .hero-image {
            z-index: -2;
            width: 100%;
            min-height: 750px;
            height: 100vh;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptopL} {
        .hero-image {
            width: 100%;

            img {
                max-height: 900px;
            }
        }
    }
    @media ${device.desktop} {
        .hero-image {
            min-height: 900px;
        }
    }
`;

const Text = styled.div`
    padding: 34px 30px;
    > button {
        margin: 20px auto;
        display: flex;
        div {
            position: relative;
            left: 9px;
            top: 1px;
        }
    }
    h3 {
        font-family: 'Noto Serif';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 132.5%;
    }
    .review {
        height: 150px;
    }
    h3 + p {
        color: ${colors.grey};
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        margin-top: 0em;
        font-size: 24px;
        line-height: 132.5%;
    }
    @media ${device.tablet} {
        width: 400px;
        h3 {
            max-width: 500px;
            margin-top: 50px;
            font-size: 32px;
            line-height: 142.5%;
        }
    }
    @media ${device.laptopS} {
        width: 1120px;
        transition: padding 0.2s ease-in;
        margin: 0 auto;
        padding: 50px 0px 0px;

        margin-left: 125px;
        h3 {
            font-weight: 600;
            font-size: 40px;
            max-width: 488px;
            margin-bottom: 20px;
            margin-top: 0px;
            line-height: 132.5%;
        }
        p {
            max-width: 500px;
        }
        .main-button {
            margin-left: 130px;
        }
        h3 + p {
            font-weight: 700;
            font-size: 32px;
            width: 384px;
            line-height: 132.5%;
        }
        .white-line {
            width: 408px;
            max-width: 408px;
        }
        .avatar {
            margin-top: -10px;
        }
        .two {
            min-width: 490px;
        }
    }
    @media ${device.laptopL} {
        position: relative;
        bottom: 20px;
        padding: 70px 0px 0px 0px;
    }

    @media ${device.desktop} {
        margin: 0 auto;

        transition: padding 0.2s ease-in;
        padding: 80px 0px 0px 0px;
        .white-line {
            width: 508px;
        }

        h3 {
            font-weight: 600;
            font-size: 54px;
            max-width: 648px;
        }
    }
    @media ${device.desktopL} {
        margin-left: 315px;
    }
`;

const Reviews = styled.section``;

const Review = styled.div`
    .avatar {
        width: 65px;
        height: 65px;
        border-radius: 200px;
        img {
            border-radius: 200px;
        }
    }
    .image-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 343px;
    }
    p {
        font-family: 'lato';
        margin-top: 25px;
    }

    .name-container {
        display: flex;
        align-items: center;
        height: 80px;
        p {
            margin-left: 20px;
            min-width: 70px;
            margin-bottom: 65px;
        }
    }
    @media ${device.tablet} {
        .image-container {
            width: 400px;
        }
    }
    @media ${device.laptopS} {
        .image-container {
            width: 490px;
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
    width: 355px;
    @media ${device.tablet} {
        justify-content: space-between;
        margin: 0;
        width: 415px;
        padding: 0px;
    }
    @media ${device.laptopS} {
        justify-content: space-between;
        margin: 0;
        width: 485px;
        padding: 0px;
    }
`;

const reviews = [
    {
        name: 'Lydia M.',
        review: 'The entire team were friendly, very attentive & educated me on some questions I had. Highly recommend the dentist here. Great experience!',
        rating: 5,
    },
    {
        name: 'Kistine U.',
        review: 'I love Woodmoor Family Dentistry. We have been patients for the last year. I also take my kids here and have always had a positive experience.',
        rating: 5,
    },
    {
        name: 'Blaine L.',
        review: 'The Woodmoor Family Dentistry is such a professional and friendly practice. Dr Andrew Dethloff saw me last minute before I was due to travel.  He’s an absolute star!! Highly recommend.',
        rating: 5,
    },
    {
        name: 'Bob B.',
        review: 'Wow! What a great new Dentist we have here in Monument. Dr D. And his staff are the best! Very professional and caring. My whole family will be now be using Woodmoor Dental',
        rating: 5,
    },
    {
        name: 'Tracy S.',
        review: 'Truly an excellent choice for a kids dentist. This office is wonderful professional and very kind to kids and adults! I can not be more grateful for such a wonderful Dentist! Thank you Dr.!',
        rating: 5,
    },
];

export const HomeReview: React.FC = () => {
    const [index, setIndex] = useState(0);

    function prevSlide(ind: number) {
        if (ind > 0) setIndex(i => i - 1);
    }
    function nextSlide(ind: number) {
        if (ind < reviews.length - 1) setIndex(i => i + 1);
    }

    function picRender(index: number) {
        return (
            (index === 0 && (
                <StaticImage
                    className="avatar"
                    src="../images/profile-pic-1.jpeg"
                    placeholder="blurred"
                    quality={100}
                    alt="girl smiling"
                />
            )) ||
            (index === 1 && (
                <StaticImage
                    className="avatar"
                    src="../images/profile-pic-2.jpeg"
                    placeholder="blurred"
                    quality={100}
                    alt="girl smiling"
                />
            )) ||
            (index === 2 && (
                <StaticImage
                    className="avatar"
                    src="../images/profile-pic-3.jpeg"
                    placeholder="blurred"
                    quality={100}
                    alt="girl smiling"
                />
            )) ||
            (index === 3 && (
                <StaticImage
                    className="avatar"
                    src="../images/profile-pic-4.jpeg"
                    placeholder="blurred"
                    quality={100}
                    alt="girl smiling"
                />
            )) ||
            (index === 4 && (
                <StaticImage
                    className="avatar"
                    src="../images/profile-pic-5.jpeg"
                    placeholder="blurred"
                    quality={100}
                    alt="girl smiling"
                />
            ))
        );
    }
    return (
        <StyledHomeReview>
            <ImageContainer>
                <StaticImage
                    className="hero-image"
                    alt="dentist showing patient a screen"
                    src="../images/doctor-pointing-shadow.jpeg"
                    placeholder="none"
                    quality={100}
                />
                <Text>
                    <h3>Successful Smiles</h3>
                    <p>What Our Patients Say About Us</p>
                    <div className="white-line two"></div>
                    <Reviews>
                        <Review>
                            {reviews.map((review, i) => {
                                return (
                                    <>
                                        {index === i && (
                                            <>
                                                <div className="flash-up image-container">
                                                    {picRender(index)}
                                                    <div className=" stars">
                                                        <StaticImage
                                                            src="../images/svgs/star.svg"
                                                            placeholder="blurred"
                                                            quality={100}
                                                            alt="star"
                                                        />
                                                        <StaticImage
                                                            src="../images/svgs/star.svg"
                                                            placeholder="blurred"
                                                            quality={100}
                                                            alt="star"
                                                        />
                                                        <StaticImage
                                                            src="../images/svgs/star.svg"
                                                            placeholder="blurred"
                                                            quality={100}
                                                            alt="star"
                                                        />
                                                        <StaticImage
                                                            src="../images/svgs/star.svg"
                                                            placeholder="blurred"
                                                            quality={100}
                                                            alt="star"
                                                        />
                                                        <StaticImage
                                                            src="../images/svgs/star.svg"
                                                            placeholder="blurred"
                                                            quality={100}
                                                            alt="star"
                                                        />
                                                    </div>
                                                </div>
                                                <p className="review flash-up">{review.review}</p>
                                                <div className="name-container">
                                                    <div className="white-line"></div>
                                                    <p className="">{review.name}</p>
                                                </div>
                                            </>
                                        )}
                                    </>
                                );
                            })}
                        </Review>
                    </Reviews>
                    <ButtonsContainer>
                        <button
                            onClick={() => prevSlide(index)}
                            style={{ background: 'none', border: 'none' }}
                        >
                            <StaticImage
                                style={{
                                    opacity: `${index === 0 ? '.2' : '1'}`,
                                }}
                                src="../images/svgs/back.svg"
                                placeholder="none"
                                quality={100}
                                alt="next review"
                            />
                        </button>
                        <p className="primary-font">
                            {index + 1}/{reviews.length}
                        </p>
                        <button
                            onClick={() => nextSlide(index)}
                            style={{ opacity: '1', background: 'none', border: 'none' }}
                        >
                            <StaticImage
                                style={{
                                    opacity: `${index === reviews.length - 1 ? '.2' : '1'}`,
                                }}
                                src="../images/svgs/forward.svg"
                                placeholder="none"
                                quality={100}
                                alt="next review"
                            />
                        </button>
                    </ButtonsContainer>
                    <Button className="main-button" color="white">
                        Read more Reviews{' '}
                        <StaticImage
                            src="../images/svgs/forward.svg"
                            placeholder="none"
                            quality={100}
                            alt="read more"
                        />
                    </Button>
                </Text>
            </ImageContainer>
        </StyledHomeReview>
    );
};
