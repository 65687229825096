import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { device } from './GlobalStyles';

const StyledImageContainer = styled.div`
    height: 95vh;
    .shadow {
        z-index: -1;
        background: black;
        opacity: 0.4;
        height: 102vh;
        width: 100%;
        position: absolute;
    }
    .hero-image {
        min-height: 500px;
        z-index: -2;
        height: 95vh;
        width: 150%;
        position: absolute;
        right: 0px;
    }
    @media ${device.tablet} {
        .shadow {
            height: 88vh;
        }
        .hero-image {
            z-index: -2;
            height: 75vh;
            width: 100%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptop} {
        .shadow {
            display: none;
        }
        .hero-image {
            z-index: -2;
            height: 100vh;
            width: 100%;
            position: absolute;
            right: 000px;
        }
    }
`;

export const ImageContainer: React.FC = ({ children }) => {
    return <StyledImageContainer>{children}</StyledImageContainer>;
};
