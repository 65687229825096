import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device, colors } from './layout/GlobalStyles';
import { Link } from 'gatsby';
import { Button } from './layout/StyledComponents';
import { details } from './details';

const HeroContainer = styled.div`
    height: 95vh;

    .shadow {
        z-index: -1;
        background: black;
        opacity: 0.4;
        height: 95vh;
        width: 100%;
        min-height: 500px;
        position: absolute;
    }
    button {
        margin-top: 32px;
    }
    .hero-image {
        min-height: 500px;
        z-index: -2;
        height: 95vh;
        width: 150%;
        position: absolute;
        right: 0px;
    }
    @media ${device.tablet} {
        .shadow {
            height: 100vh;
        }
        .hero-image {
            z-index: -2;
            height: 100%;
            width: 150%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptop} {
        .shadow {
            height: 100vh;
        }
        .hero-image {
            z-index: -2;
            height: 100vh;
            width: 100%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptopL} {
        .payment-button {
            display: none;
        }
    }
`;

const Content = styled.div`
    z-index: 4;
    padding-top: 118px;
    padding-left: 35px;
    h1 {
        font-family: 'Noto Serif';
        font-style: normal;
        max-width: 494px;
        font-weight: 600;
        margin-bottom: 0em;
        font-size: 45px;
        line-height: 61px;
        color: ${colors.offWhite};
    }
    .address-container {
        align-items: center;
        display: flex;
        margin-bottom: 20px;
        margin-top: 80px;
        p {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 45px;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-decoration-line: underline;
            font-family: 'lato';
            color: ${colors.offWhite};
        }
    }
    @media ${device.tablet} {
        padding-top: 128px;
        h1 {
            max-width: 864px;
            font-style: normal;
            font-weight: 600;
            font-size: 80px;
            line-height: 109px;
        }
        .address-container {
            margin-top: 50px;
            margin-bottom: 20px;
            p {
                font-weight: 600;
                font-size: 22px;
            }
        }
    }
    @media ${device.laptopS} {
        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 80px;
            max-width: 784px;
            line-height: 109px;
        }
    }
    @media ${device.laptop} {
        padding-top: 17.5vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding-left: 5vw;
        transition: padding 0.2s ease-in;
        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 80px;
            max-width: 784px;
            line-height: 109px;
        }
        .address-container {
            margin-top: 50px;
            margin-bottom: 20px;
            p {
                font-weight: 600;
                font-size: 22px;
            }
        }
    }
    @media ${device.desktop} {
        padding-top: 20vh;
        padding-left: 10vw;
        h1 {
            transition: font-size 0.2s ease-in;
            max-width: 894px;
            font-size: 88px;
        }
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <HeroContainer>
            <div className="shadow"></div>
            <StaticImage
                className="hero-image"
                src="../images/family-smiling.jpeg"
                alt="family smiling together"
                placeholder="none"
                quality={100}
                loading="eager"
            />
            <Content>
                <h1>Our family is here for your family</h1>
                <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/peB14nSHhvuGm2ph7">
                    <div className="address-container">
                        <StaticImage
                            src="../images/svgs/marker-white.svg"
                            placeholder="none"
                            quality={100}
                            alt="location"
                        />
                        <p>{details.address}</p>
                    </div>
                </a>
                <Link to="tel:(719) 488-3014">
                    <Button color="blue">
                        <StaticImage
                            src="../images/svgs/phone-white.svg"
                            alt="white mobile phone"
                            placeholder="none"
                            quality={100}
                        />
                        Call us at (719) 488-3014
                    </Button>
                </Link>
                <a
                    href="https://link.clover.com/urlshortener/wNdBTJ"
                    target="__blank"
                    rel="noopener noreferrer"
                >
                    <Button className="payment-button" color="blue">
                        Make Payment
                    </Button>
                </a>
            </Content>
        </HeroContainer>
    );
};
