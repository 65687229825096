import React from 'react';
import styled from '@emotion/styled';
import { device } from './GlobalStyles';

interface Props {
    reverse?: boolean;
}

const StyledContentContainer = styled.div<Props>`
    max-width: 320px;
    margin: 0 auto;
    ul {
        padding: 0em 0em 0em 1em;
    }
    @media ${device.mobileL} {
        max-width: 345px;
        margin: 0 auto;
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 675px;
        margin: 0 auto;
    }
    @media ${device.laptopS} {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 725px;
        margin: 0 auto;
    }
    @media ${device.laptop} {
        justify-content: space-between;
        flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
        max-width: 1064px;
        margin: 0 auto;
    }
    @media ${device.desktop} {
        max-width: 1205px;
        margin: 0 auto;
    }
`;

export const ContentContainer: React.FC<Props> = ({ children, reverse }) => {
    return (
        <StyledContentContainer reverse={reverse} className="panel-container">
            {children}
        </StyledContentContainer>
    );
};
