import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { Link } from 'gatsby';
import oral from '../images/svgs/oral-surgery.svg';

const StyledHomeServices = styled.div`
    .woods-desktop-image {
        display: none;
    }

    @media ${device.tablet} {
        .panels-container {
            display: flex;
            flex-wrap: wrap;
        }
    }
    @media ${device.laptopS} {
        .panels-container {
            margin: 0 auto;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
        }
        .shadow-desktop {
            display: none;
        }
    }
    @media ${device.laptop} {
        display: flex;
        flex-wrap: wrap;
        .shadow-desktop {
            display: block;
            width: 100vw;
            opacity: 0.4;
            height: 400px;
            position: absolute;
            z-index: -1;
            position: absolute;
            background: black;
            opacity: 0.7;
        }

        .woods-desktop-image {
            position: absolute;
            display: block;
            height: 400px;
            width: 100vw;
            z-index: -2;
        }
    }
    @media ${device.laptopS} {
        .shadow-desktop {
            height: 400px;
        }

        .woods-desktop-image {
            position: absolute;
            display: block;
            height: 400px;
            width: 100vw;
            z-index: -2;
        }
    }
`;

const Panel = styled.div`
    position: relative;
    width: 100vw;
    height: 416px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .woods {
        z-index: -2;
        position: absolute;
        top: 0em;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        img {
            filter: brightness(30%);
            height: 416px;
        }
    }
    /* .shadow {
        z-index: -1;
        position: absolute;
        background: black;
        opacity: 0.4;
        width: 100vw;
        height: 416px;
    } */

    p {
        font-weight: 400;
        font-family: 'lato';

        z-index: 2;
        color: ${colors.offWhite};
    }
    p:first-of-type {
        font-size: 24px;
        font-weight: 700;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }
    p:last-of-type {
        margin-top: 0em;
        width: 275px;
        font-size: 16px;
        min-height: 170px;
        text-align: center;
        font-family: 'lato';
    }
    @media ${device.tablet} {
        width: 50vw;
    }
    @media ${device.laptopS} {
        img {
            transition: 0.2s ease-in-out;
        }
        &:hover {
            img {
                transition: 0.2s ease-in-out;
                transform: scale(0.9);
            }
            p:first-of-type {
                text-decoration: underline;
            }
        }
        width: 50vw;
    }
    @media ${device.laptop} {
        width: 320px;
        display: flex;
        align-self: center;
        height: 420px;
        .woods {
            display: none;
        }
    }
    @media ${device.desktop} {
        width: 400px;
        p:first-of-type {
            font-size: 30px;
            font-weight: 700;
            margin-top: 1em;
        }

        p:last-of-type {
            margin-top: 0em;
            width: 280px;
            font-size: 20px;
            line-height: 34px;
            text-align: center;
            font-family: 'lato';
        }
    }
    /* @media ${device.desktopL} {
        width: 420px;
        p:first-of-type {
            font-size: 32px;
            font-weight: 700;
            margin-top: 1em;
        }

        p:last-of-type {
            margin-top: 0em;
            width: 280px;
            font-size: 25px;
            line-height: 34px;
            text-align: center;
            font-family: 'lato';
        }
    } */
`;

export const HomeServices: React.FC = () => {
    return (
        <StyledHomeServices>
            <div className="shadow"></div>
            <div className="panels-container">
                <Link to="/emergency-dentistry/">
                    <Panel>
                        <StaticImage
                            layout={'fixed'}
                            className="woods"
                            src="../images/home-office.jpeg"
                            placeholder="none"
                            quality={100}
                            alt="woodmor office"
                        />
                        <StaticImage
                            src="../images/svgs/emergency-dentist.svg"
                            placeholder="none"
                            quality={100}
                            alt="emergency dentistry"
                        />
                        <p>Emergency Dentistry</p>
                        <p>
                            Accidents happen, and trauma to the tooth can lead to serious oral
                            diseases. You can be confident our emergency dental care services will
                            quickly serve you.
                        </p>
                    </Panel>
                </Link>
                <Link to="/general-dentistry/">
                    <Panel>
                        <div className="shadow"></div>
                        <StaticImage
                            layout={'fixed'}
                            className="woods"
                            src="../images/home-office.jpeg"
                            placeholder="none"
                            quality={100}
                            alt="woodmor office"
                        />{' '}
                        <StaticImage
                            src="../images/svgs/general-dentistry.svg"
                            placeholder="none"
                            quality={100}
                            alt="general dentistry"
                        />
                        <p>General Dentistry</p>
                        <p>
                            We are equipped in state-of-the-art technologies to provide general oral
                            services such as routine cleanings, gum disease treatment, and more.
                        </p>
                    </Panel>
                </Link>
                <Link to="/cosmetic-dentistry/">
                    <Panel>
                        <div className="shadow"></div>
                        <StaticImage
                            layout={'fixed'}
                            className="woods"
                            src="../images/home-office.jpeg"
                            placeholder="none"
                            quality={100}
                            alt="woodmor office"
                        />{' '}
                        <StaticImage
                            src="../images/svgs/cosmetic-dentistry.svg"
                            placeholder="none"
                            quality={100}
                            alt="cosmetic dentistry"
                        />
                        <p>Cosmetic Dentistry</p>
                        <p>
                            Beautiful teeth make a great first impression. Our veneers and
                            professional teeth whitening services can enhance the appearance of your
                            overall smile.
                        </p>
                    </Panel>
                </Link>
                <Link to="/oral-surgery/">
                    <Panel>
                        <div className="shadow"></div>
                        <StaticImage
                            layout={'fixed'}
                            className="woods"
                            src="../images/home-office.jpeg"
                            placeholder="none"
                            quality={100}
                            alt="woods"
                        />{' '}
                        <img src={oral} alt="general dentistry" />
                        <p>Oral Surgery</p>
                        <p>
                            From wisdom tooth removals to general tooth extractions, and root canal
                            treatments, our expert surgeons have the skills and experience to
                            restore your teeth.
                        </p>
                    </Panel>
                </Link>
            </div>
            <div className="shadow-desktop"></div>
            <StaticImage
                className="woods-desktop-image"
                src="../images/home-office.jpeg"
                alt="patient chair in woodmor dental"
                placeholder="none"
                quality={100}
            />
        </StyledHomeServices>
    );
};
