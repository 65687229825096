import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ContentContainer } from './layout/Content-Container';
import { HomeText } from './layout/Home-Text';

const QualityCareContainer = styled.div`
    margin: 40px 0px 40px;
    .tree-image {
        display: none;
    }
    @media ${device.laptopS} {
        position: relative;
        padding: 85px 0px 65px;
        .tree-image {
            transition: width 0.2s ease-in;
            z-index: -3;
            right: 10vw;
            position: absolute;
            bottom: -4em;
            display: block;
            width: 820px;
            opacity: 0.5;
        }
    }
    @media ${device.laptop} {
        position: relative;
        padding: 128px 0px 148px;
        .tree-image {
            width: 880px;
        }
    }
    @media ${device.laptopL} {
        padding: 128px 0px 148px;
        .tree-image {
            width: 980px;
        }
    }
`;

const ImageContainer = styled.div`
    .blue-squares {
        display: none;
    }
    .main-image {
        margin-bottom: 50px;
        img {
            border-radius: 100px 20px;
        }
    }
    @media ${device.tablet} {
        display: flex;
        justify-content: center;
        .main-image {
            margin-bottom: 00px;
            width: 75%;
            margin-top: 50px;
        }
        .blue-squares {
            display: none;
        }
    }
    @media ${device.laptopS} {
        .main-image {
            width: 622px;
            margin-top: 40px;
        }
        .blue-squares {
            display: none;
        }
    }
    @media ${device.laptop} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 516px;
        height: 516px;
        left: 80px;
        position: relative;
        .main-image {
            width: 452px;
            img {
                border-radius: 100px 20px;
            }
        }
        .blue-squares {
            display: block;
            width: 180px;
            border-radius: 60px 20px;
            z-index: -1;
            position: absolute;
            height: 180px;
            background: ${colors.blueDark};
        }
        .blue-squares:first-of-type {
            top: 0em;
            right: -2em;
        }
        .blue-squares:last-of-type {
            bottom: 0em;
            left: -2em;
        }
    }
    @media ${device.laptopL} {
        width: 616px;
        height: 616px;
        .main-image {
            width: 522px;
        }
    }
    @media ${device.desktop} {
        left: 40px;
    }
`;

const text = {
    title: `Quality Dental Care in Monument`,
    subTitle: `Family-Friendly Oral Care`,
    bodyText: (
        <>
            At Woodmoor Family Dentistry, you can rest assured your oral care and well-being are in
            the proficient and professional hands of our highly experienced and compassionate team
            of dentists. We are proud to offer a comprehensive suite of{' '}
            <span> emergency, general, cosmetic, </span> and
            <span> surgical dental services</span> for you and your entire family. You will notice
            the welcoming and warm demeanor of our staff members as soon as you enter our doors and
            we will be upfront with you on honesty and transparency, regarding both treatments and
            procedure costs from the get-go. Affordability and availability are the two main factors
            we run on to deliver patients with top-notch patient care. We are proud to be one of the
            most affordable dental practices in Monument, offering an array of financing options to
            help meet your needs and goals.
        </>
    ),
};

export const QualityCare: React.FC = () => {
    return (
        <QualityCareContainer>
            <ContentContainer>
                <HomeText>
                    <h2>{text.title}</h2>
                    <p>{text.subTitle}</p>
                    <div></div>
                    <p>{text.bodyText}</p>
                </HomeText>
                <ImageContainer>
                    <div className="blue-squares"></div>
                    <StaticImage
                        className="main-image"
                        src="../images/woodmor-door.jpeg"
                        alt="dentist giving patient a high five"
                        quality={100}
                        placeholder="none"
                    />
                    <div className="blue-squares"></div>
                </ImageContainer>
            </ContentContainer>
            <StaticImage
                className="tree-image"
                src="../images/trees.png"
                alt="dentist operating on patient"
                quality={100}
                placeholder="none"
            />
        </QualityCareContainer>
    );
};
