import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ImageContainer } from './layout/Image-Container';
import { device, colors } from './layout/GlobalStyles';

const StyledWhyTheBest = styled.div`
    color: ${colors.offWhite};
    .hero-image {
        z-index: -2;
        padding-bottom: 04px;
        min-height: 740px;
        width: 170%;
        position: absolute;
        right: 00px;
        filter: brightness(80%);
    }
    .white-line {
        height: 1px;
        margin-bottom: 40px;
        width: 87vw;
        background: ${colors.offWhite};
    }

    @media ${device.tablet} {
        margin-top: 80px;
        .white-line {
            width: 52vw;
        }

        .hero-image {
            padding-bottom: 120px;
            z-index: -2;
            height: 70%;

            width: 150%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.laptop} {
        .hero-image {
            min-height: 680px;
            z-index: -2;

            width: 100%;
            position: absolute;
            right: 000px;
        }
    }
    @media ${device.desktop} {
        .hero-image {
            min-height: 780px;
        }
    }
`;

const Text = styled.div`
    padding: 34px 30px;
    h3 {
        font-family: 'Noto Serif';
        font-style: normal;
        font-weight: 600;

        font-size: 28px;
        line-height: 132.5%;
    }
    h3 + p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 132.5%;
    }
    ul {
        padding: 0em;
    }
    li {
        list-style: none;
        position: relative;
        display: flex;
        width: 355px;
        justify-content: flex-start;
        align-items: center;
        p {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
        }
        div {
            margin-right: 15px;
            width: 62px;
            height: 55px;
            img {
                width: 45px;
                height: 45px;
            }
        }
    }
    @media ${device.tablet} {
        h3 {
            max-width: 500px;
            margin-top: 0em;
            font-size: 32px;
            line-height: 142.5%;
        }
    }
    @media ${device.laptop} {
        transition: padding 0.2s ease-in;
        margin: 0 auto;
        padding: 50px 0px 0px 110px;

        h3 {
            font-weight: 600;
            font-size: 40px;
            max-width: 488px;
            margin-bottom: 20px;
            margin-top: 0px;
            line-height: 132.5%;
        }
        li {
            width: 500px;
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 152.5%;
            }
        }
        h3 + p {
            font-weight: 700;
            font-size: 32px;
            max-width: 500px;
            line-height: 132.5%;
        }
        .white-line {
            width: 408px;
            max-width: 408px;
        }
    }
    @media ${device.laptopL} {
        padding: 40px 0px 0px 180px;
    }
    @media ${device.desktop} {
        margin: 0 auto;
        transition: padding 0.2s ease-in;
        padding: 60px 0px 0px 320px;
        h3 {
            font-weight: 600;
            font-size: 54px;
            max-width: 648px;
        }
    }
`;

export const WhyTheBest: React.FC = () => {
    return (
        <StyledWhyTheBest>
            <ImageContainer>
                <StaticImage
                    className="hero-image"
                    alt="dentist pointing"
                    src="../images/family-of-four-hugging.jpeg"
                    placeholder="none"
                    quality={100}
                />
                <Text>
                    <h3>{`Why We're The Best Dental Option In Monument`}</h3>
                    <p>High Quality and Family Focused Dental Care</p>
                    <div className="white-line"></div>
                    <ul>
                        <li>
                            <StaticImage
                                alt="dentist icon"
                                src="../images/svgs/family-friendly.svg"
                                placeholder="none"
                                quality={100}
                            />
                            <p>Family Friendly Atmosphere</p>
                        </li>
                        <li>
                            {' '}
                            <StaticImage
                                alt="card swiper icon"
                                src="../images/svgs/offering.svg"
                                placeholder="none"
                                quality={100}
                            />
                            <p>Offering the Most Affordable Dental Care in Monument</p>
                        </li>
                        <li>
                            {' '}
                            <StaticImage
                                alt="tooth icon"
                                src="../images/svgs/providing-comfort.svg"
                                placeholder="none"
                                quality={100}
                            />
                            <p>Providing Comfort & Care is Our Top Priority</p>
                        </li>

                        <li>
                            {' '}
                            <StaticImage
                                alt="dentist icon"
                                src="../images/svgs/offer-sedation.svg"
                                placeholder="none"
                                quality={100}
                            />
                            <p>We Offer Sedation Services for Your Comfort</p>
                        </li>
                        <li>
                            {' '}
                            <StaticImage
                                alt="calender icon"
                                src="../images/svgs/available-same.svg"
                                placeholder="none"
                                quality={100}
                            />
                            <p>Available for Same-Day & Next-Day Appointments</p>
                        </li>
                    </ul>
                </Text>
            </ImageContainer>
        </StyledWhyTheBest>
    );
};
