import React from 'react';
import styled from '@emotion/styled';
import { HomeText } from './layout/Home-Text';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ContentContainer } from './layout/Content-Container';

const StyledSetsUsApart = styled.div`
    padding: 0px 0px 74px;
    /* images */
    .image {
        margin-top: 20px;
        img {
            border-radius: 100px 20px;
        }
        border: none;
        border-radius: 100px 20px;
    }
    .second-panel {
        background: teal;
    }
    @media ${device.tablet} {
        padding: 0px 0px;
        max-width: 580px;

        h2 {
            margin: 54px 0px 15px;
        }

        .image {
            width: 400px;
        }
    }
    @media ${device.laptopS} {
        > div {
            margin: 0em 0em 50px;
        }
        padding: 108px 0px 70px;
        .image {
            width: 400px;
        }
    }
    @media ${device.laptopL} {
        .image {
            width: 440px;
        }
    }
    @media ${device.desktop} {
        .image {
            width: 484px;
        }
    }
`;

const text = [
    {
        title: `What Sets Us Apart?`,
        subTitle: `More Accessibility to Dental Care`,
        bodyText: (
            <>
                We truly go out of our way to offer the best possible care for our patients. We even
                offer{' '}
                <span style={{ color: `${colors.offBlack}` }}>
                    FREE digital x-rays for our new patients without insurance;
                </span>{' '}
                {`because we know what's necessary to keep your oral health at its highest quality set
            forth by the American Dental Association (ADA) standards. Patients can also enjoy our
            same-day appointment scheduling options, as well as free consultations both in-person
            and over-the-phone.`}
            </>
        ),
    },
    {
        bodyText: (
            <>
                <p style={{ marginTop: '-30px' }}>
                    A few benefits patients can expect at Woodmoor Family Dentistry include:
                </p>
                <ul>
                    <li>One of the few practices that accept Medicaid </li>
                    <li>Understands dental anxieties and common fears</li>
                    <li>Free x-ray for new patients without Insurance</li>
                    <li>Offers payment plans</li>
                    <li>Same-day appointments </li>
                    <li>Free consultations</li>
                    <li>Wide variety of sedation dentistry options</li>
                </ul>
            </>
        ),
    },
];

export const SetsUsApart: React.FC = () => {
    return (
        <StyledSetsUsApart className="container">
            <ContentContainer>
                {' '}
                <HomeText>
                    <h2>{text[0].title}</h2>
                    <p>{text[0].subTitle}</p>
                    <div></div>
                    <p>{text[0].bodyText}</p>
                </HomeText>
                <StaticImage
                    className="image"
                    src="../images/dentist-inspecting.jpeg"
                    alt="Dentist inspecting a patient's mouth"
                    placeholder="none"
                    quality={100}
                />
            </ContentContainer>
            <ContentContainer reverse>
                <HomeText>
                    <h2>{text[1].title}</h2>
                    <p>{text[1].subTitle}</p>
                    <p>{text[1].bodyText}</p>
                </HomeText>{' '}
                <StaticImage
                    className="image"
                    src="../images/dentist-hi-five.jpeg"
                    alt="dentist giving young patient a hi five"
                    placeholder="none"
                    quality={100}
                />
            </ContentContainer>
        </StyledSetsUsApart>
    );
};
