import { HomeHero } from '../components/Home-Hero';
import { QualityCare } from '../components/Quality-Care';
import { HomeServices } from '../components/Home-Services';
import { DefaultLayout } from '../layout/DefaultLayout';
import { SetsUsApart } from '../components/Sets-Us-Apart';
import { WhyTheBest } from '../components/Why-The-Best';
import { FormPanels } from '../components/Form-Panels';
import { HomeReview } from '../components/Home-Review';
import { Seo } from '../components/Seo';

const Home: Page = () => (
    <>
        <Seo
            title="Woodmoor Family Dentistry"
            description="Woodmoor Family Dentistry is proud to be one of the best dental practices in Monument and surrounding areas. Contact us for more information today!"
        />
        <HomeHero />
        <QualityCare />
        <HomeServices />
        <SetsUsApart />
        <WhyTheBest />
        <FormPanels />
        <HomeReview />
    </>
);

Home.Layout = DefaultLayout;

export default Home;
